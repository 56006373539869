import InputText from "../general/InputText";
import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import InputSubmit from "../general/FormSubmit";
import { motion } from "framer-motion";

export default function Footer({ siteConfig, storyBlok }) {
  const animationText = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        type: "tween",
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  };
  const footer = siteConfig?.content?.footer[0];
  footer.Text = 'Via Cagliari, snc 20060 Trezzano Rosa (MI)';
  footer.Text2 = 'Magazzino: Via Cagliari, snc 20060 Trezzano Rosa (MI)';
  
  const socialLinks = {
    Facebook: "https://www.facebook.com/hydrotrade",
    Linkedin: "https://www.linkedin.com/company/hydro-trade-srl",
    Instagram: "https://www.instagram.com/hydrotradesrl/",
    Tiktok: "https://www.tiktok.com/@HydroTradeSrl",
    Youtube: "https://www.youtube.com/@HydroTradeSrl",
  };
  const socialImages = footer?.Social?.map((social, index) => (
    <a
      key={index}
      href={socialLinks[social.title]}
      className="mt-2 mr-2 inline-block"
    >
      {" "}
      {/* Add the link in href */}
      <img
        className="mx-auto rounded-full align-middle"
        src={social.filename}
        alt={social.alt || "Social Image"}
      />
    </a>
  ));

  const [isSendLoading, setSendLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setSendLoading(true);

    const mail = {
      service_id: "service_beyk31m",
      template_id: "template_uspk8la",
      user_id: "HhmWYkgnd8AeSuxop",
      template_params: {
        fisrtname: data.fisrtname,
        lasttname: data.lasttname,
        email: data.email,
      },
    };

    axios
      .post("https://api.emailjs.com/api/v1.0/email/send", mail)
      .then(function (response) {})
      .catch(function (error) {})
      .finally(function () {
        setSendLoading(false);
      });
  };

  return (
    <div className="relative isolate bg-blue ">
      <div className="mx-auto grid max-w-7xl grid-cols-1 md:grid-cols-2">
        <div className="relative lg:py-24 mt-10">
          <motion.div 
           variants={animationText}
           initial="hidden"
           whileInView="show"
           className="mx-auto max-w-xl ml-6 lg:px-8">
            <h2
           
              className="text-lg font-lexend  pb-5 text-white"
            >
              {footer?.Headline}
            </h2>
            <p className="text-base md:text-lg font-lexend  text-white">{footer?.title1}</p>
            <p className="text-base md:text-lg font-lexend  text-white pb-2">{footer?.Text}</p>
            <p className="text-base md:text-lg font-lexend  text-white">{footer?.title2}</p>
            <p className="text-base md:text-lg font-lexend  text-white">{footer?.Text2}</p>
            <div className="flex justify-start items-center">
              {socialImages}
            </div>
          </motion.div>
        </div>

        <motion.form
         variants={animationText}
         initial="hidden"
         whileInView="show"
          onSubmit={handleSubmit(onSubmit)}
          className="lg:py-24 px-6 lg:px-8"
        >
          <div className="mx-auto max-w-xl lg:max-w-lg ">
            <h2 className="text-lg font-lexend my-2 text-white">
              {footer?.Titlle}
            </h2>

            <div className="grid grid-cols-1 gap-x-4 gap-y-6 lg:grid-cols-2">
              <div className="col-span-full">
                <InputText
                  id={"fullname"}
                  type={"text"}
                  className={"relative mt-2 flex items-center"}
                  errors={errors.fullname}
                  input={{
                    ...register("fullname", {
                      required: true,
                      minLength: {
                        value: 6,
                        message: "Last name must be at least 6 characters",
                      },
                    }),
                  }}
                  label={""}
                  inputClass={
                    "block w-full rounded-full border-0 px-4 py-3 font-lexend text-gray focus:ring-white sm:text-sm sm:leading-6"
                  }
                  placeholder={"Fullname"}
                />
              </div>

              <div className="col-span-full">
                <div className="relative mt-2 flex items-center">
                  <InputText
                    id={"email"}
                    className={"w-full"}
                    type={"text"}
                    errors={errors.email}
                    input={{
                      ...register("email", {
                        required: true,
                        minLength: {
                          value: 6,
                          message: "Last name must be at least 6 characters",
                        },
                      }),
                    }}
                    label={""}
                    inputClass={
                      "block w-full rounded-full border-0 px-4 py-3 font-lexend text-gray focus:ring-white sm:text-sm sm:leading-6"
                    }
                    placeholder={"mail"}
                  />
                  <div className="absolute inset-y-0 right-0 flex py-1 pr-1.5">
                    <InputSubmit
                      label={footer?.button}
                      isLoading={isSendLoading}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-3 relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id="comments"
                  aria-describedby="comments-description"
                  name="comments"
                  type="checkbox"
                  className="h-4 w-4 rounded border-blue text-black focus:ring-blue"
                />
              </div>

              <div className="ml-3 mb-5 text-sm leading-6">
                <div
                  htmlFor="comments"
                  className="mb-2 font-sans font-medium text-white"
                  dangerouslySetInnerHTML={{
                    __html: storyBlok.richTextResolver.render(
                      footer?.label ?? ""
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </motion.form>
      </div>
      <motion.footer
       variants={animationText}
       initial="hidden"
       whileInView="show"
      className="bg-black">
        <div className="hidden lg:block mx-auto max-w-7xl px-5 py-5 flex items-center justify-between lg:px-8">
          <div className="flex justify-center space-x-6 md:order-2">
            <p className="font-lexend text-center text-xs leading-5 text-white">
              {footer?.text1}
            </p>
            <p className="font-lexend text-center text-xs leading-5 text-white">
              {footer?.text2}
            </p>
          </div>
          <div className=" flex justify-center space-x-6 md:order-2">
            <p className="text-center font-lexend text-xs leading-5 text-white">
              {footer?.text3}
            </p>
            <p className="text-center font-lexend text-xs leading-5 text-white">
              {footer?.text4}
            </p>
          </div>
        </div>

        <div className="lg:hidden mx-auto max-w-7xl px-5 py-5  ">
          <div className=" flex flex-col items-center text-center">
            <p className="text-center font-lexend text-xs leading-5 text-white mb-2">
              {footer?.text3}
            </p>
            <p className="text-center font-lexend text-xs leading-5 text-white">
              {footer?.text4}
            </p>
          </div>
        </div>
      </motion.footer>
    </div>
  );
}
